@use "../../styles/colors";

.root {
  min-width: 800px;
  max-width: 1600px;
  padding: 0.5rem;
  margin: auto;
  margin-bottom: 2rem;
  z-index: 1;
  position: relative;
}

.bordered {
  padding: 1.5rem;
  border: 1px solid colors.$middle-light-gray;
}

.divider {
  margin: 24px -24px;
}
