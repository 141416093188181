@use "../../styles/colors";

.root {
  margin: auto;
  max-width: 1600px;
  min-width: 800px;
}

.accordion {
  padding: 0.5rem;
  border-color: colors.$middle-light-gray;
  border-radius: 4px;
}
