@use "../../styles/colors";

.box {
  overflow: auto;
  max-height: 600px;
  flex-grow: 1;

  a {
    color: colors.$black;
  }
}
