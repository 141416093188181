// Pixelpoint Primary
$pixel-point-blue: #0073e5;
$pixel-point-blue-hover: #0050a0; // follows mui button standard
$pixel-point-blue-medium-hover: #0073e54a;
$pixel-point-blue-light-hover: #0073e50a;

// Pixelpoint Secondary
$pale-prim: #fff699;
$free-speech-red: #cc0000;
$malachite: #00cc66;

// Pixelpoint Semitones
$semitone-blue: #4da6ff;
$semitone-pale-prim: #fff9b3;
$semitone-tan: #fcd49c;
$semitone-tan-light: #fef4e5;
$semitone-tan-very-light: #fffbf5;

// Pixelpoint Grayscale
$black: #17191c;
$black-shadow: #17191c14;
$darkest-gray: #2e3138;
$very-dark-gray: #454a54;
$dark-gray: #5c6370;
$middle-dark-gray: #8f96a3;
$middle-gray: #abb0ba;
$middle-light-gray: #c7cad1;
$light-gray: #e3e5e8;
$very-light-gray: #eeeff1;
$lightest-gray: #f3f3f6;
$background-white: #fafafa;
$white: #ffffff;

// not Pixelpoint
$gradient-gray: #d9d9d9; // only used for a few gradients, which match our website.
$disabled: #00000061; // not a global standard. mui defaults are used for buttons, and grayscale are used on resource cards. this (in colors.ts) affects a few param inputs.

// Web-only colors
$neon-carrot: #ffa729;

// Error colors
$mui-error-dark: #d32f2f;
