@use "../../../styles/colors";

.bg {
  min-height: 100vh;

  background: linear-gradient(
    180deg,
    colors.$gradient-gray 30.35%,
    colors.$white 80%
  );

  background-repeat: no-repeat;
  background-attachment: fixed;
}

.card {
  width: 496px;

  position: relative;
  top: 200px;
}
