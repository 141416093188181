@use "../../styles/colors";

.dialog {
  min-width: 1250px;
  min-height: 885px;
  height: calc(100vh - 50px);
}

.paper {
  overflow: hidden;
}

.snapshot-container {
  width: calc(100% - 600px);
}

.form-container {
  border: 1px solid colors.$light-gray;
  border-radius: 4px;

  padding: 16px 16px 8px 16px;

  width: 600px;
  height: 0;
  flex-grow: 1;
}
