@use "../../styles/colors";

.container {
  width: 100%;
  min-width: 800px;
  max-width: 2000px;
  padding: 0;
  margin: auto;
  z-index: 1;
  position: relative;

  border: none;
}

.card {
  border: none;
  background-image: linear-gradient(
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 100%
    ),
    radial-gradient(rgb(235, 235, 235) 2px, transparent 0);
  background-size: 20px 20px;
}

.add-resource-button {
  padding: 12px;
  border-radius: 50%;
  min-width: 0;
}

.add-resource-text {
  font-size: 12px;
  font-weight: 600;
  position: absolute;
  top: 3rem;
}

.live-draft-switcher {
  position: absolute;
  top: -10px;
}

.live-draft-switcher button {
  background-color: white;
}
