@use "../../styles/colors";

.container {
  margin: auto;
  width: 1000px;
  min-height: 600px;
  display: flex;
  flex-direction: row;
}

.left {
  min-width: 325px;
  width: 325px;
  padding: 24px 20px;

  background-color: colors.$white;

  background-repeat: no-repeat;
  background-position: left -75px bottom -155px;
  background-size: 832px;
  border-right: 1px solid colors.$light-gray;
  border-radius: 8px 0 0 8px;

  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */
}

.right {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 50px 16px 16px 32px;
  background-color: rgb(248, 249, 250);
  text-align: left;
}

.closeButton {
  position: relative;
  float: right;
  top: 12px;
  right: 48px;
}
