@use "../../styles/colors";

.current {
  opacity: 1 !important;
  background-color: none !important;

  display: flex;
  justify-content: space-between;
}

.check {
  color: colors.$pixel-point-blue;
}

.current-typo {
  color: colors.$pixel-point-blue;
  font-weight: 600;
}

.accounts-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  margin-bottom: 5px;
}

.menu {
  min-width: 250px;
}
