@use "../../styles/colors";

.editing-control-bar {
  background-color: colors.$very-light-gray;
  border: 1px solid colors.$middle-light-gray;
}

.bottom-margin {
  margin-bottom: 10px;
}

.ease-in {
  animation-name: easeIn;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;

  div {
    overflow: hidden;
  }
}

@keyframes easeIn {
  0% {
    height: 0;
    opacity: 0;
  }

  50% {
    height: 58px;
    opacity: 0;
  }

  100% {
    height: 58px;
    opacity: 1;
  }
}

.ease-out {
  animation-name: easeOut;
  animation-duration: 1.2s;
  animation-fill-mode: forwards;
}

@keyframes easeOut {
  0% {
    height: 58px;
    opacity: 1;
  }

  50% {
    height: 58px;
    opacity: 1;
  }

  70% {
    height: 58px;
    opacity: 0;
  }

  100% {
    height: 0;
    opacity: 0;
  }
}

.ease-out-slowly {
  animation-name: easeOutSlowly;
  animation-duration: 4.5s; // bar hides at 4 seconds, this makes the transition smoother
  animation-fill-mode: forwards;
}

@keyframes easeOutSlowly {
  0% {
    height: 58px;
    opacity: 1;
  }

  80% {
    height: 58px;
    opacity: 1;
  }

  90% {
    height: 58px;
    opacity: 0;
  }

  100% {
    height: 0;
    opacity: 0;
  }
}

.hidden {
  display: none;
}
