@use "../../styles/typography";
@use "../../styles/colors";

.wizard {
  z-index: 1;
  position: relative;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;

  background: linear-gradient(
    180deg,
    colors.$gradient-gray 30.35%,
    colors.$white 80%
  );

  background-repeat: no-repeat;
  background-attachment: fixed;

  padding: 2rem;
}

.stepContainer {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.licenseInput {
  margin-bottom: 8px;
  textarea {
    font-family: typography.$font-family-monospace;
    word-break: break-all;
    font-size: 14px;
    line-height: 16px;
  }
}

.loginForm {
  max-width: 400px;
}

.licenseLink {
  font-size: 14px;
  display: flex;
  align-items: center;
}

.externalLink {
  width: 14px;
  margin-left: 4px;
}
