@use "../../styles/colors";

.close {
  float: right;
}

.info {
  margin-top: 10px;
  display: inline-flex;
}
