@use "../../styles/colors";
@use "../../styles/typography";

.page {
  background-color: colors.$white;
  height: 100vh;
  width: 100vw;

  min-width: 1117px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.header-img {
  background: linear-gradient(
    90deg,
    colors.$gradient-gray 30.35%,
    colors.$white 80%
  );

  position: fixed;
  width: 105vw;
  height: 911.68px;
  top: -800px;
  left: -50px;

  transform: rotate(-3.6deg);
}

.footer-img {
  background: linear-gradient(
    90deg,
    colors.$gradient-gray 30.35%,
    colors.$white 80%
  );

  position: fixed;
  width: 105vw;
  height: 911.68px;
  bottom: -800px;
  right: -50px;

  transform: rotate(-3.6deg);
}

.logo {
  position: fixed;
  top: 20px;
  left: 40px;
  width: 185px;
}

.h {
  color: colors.$pixel-point-blue;
  font-size: 28px;
  font-weight: 600;
  font-family: typography.$font-family-nunito-sans;
}

.footer {
  position: fixed;
  bottom: 20px;

  color: colors.$white;

  z-index: 1;
}

.demo {
  position: fixed;
  top: 20px;
  right: 10vw;

  font-size: 24px;
  font-weight: 300;
  color: colors.$white;
  text-decoration: none;
}
