@use "../../styles/typography";
@use "../../styles/colors";

.editor {
  max-height: 500px;
  transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
  font-size: 12px;
  font-family: typography.$font-family-monospace;
  background-color: colors.$very-light-gray;
}

.expanded {
  max-height: 10000px;
  transition: max-height 0.5s ease-in-out;
}

.container {
  background-color: colors.$very-light-gray;
  border: 1px solid colors.$very-light-gray;
  overflow: auto;
}

.editable {
  background-color: colors.$white;
  border: 1px solid colors.$black;
  overflow: auto;
}

.bg-white {
  background-color: colors.$white;
}

.actions {
  float: right;
  padding-top: 8px;
  margin: 0px 8px 0 0;
  position: sticky;
  top: 0;
}

.btn-container {
  position: absolute;
  bottom: 32px;

  display: flex;
  justify-content: center;
  width: 100%;
}
