@use "../../styles/colors";

.link-button {
  color: colors.$pixel-point-blue;
  font-size: 12px;
  margin-top: 2px;
  &:hover {
    text-decoration: underline;
  }
}
