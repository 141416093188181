@use "../../styles/colors";

.stack {
  padding: 24px 24px;
  background: inherit;
  position: relative;
}

.periodButton {
  min-width: 50px;
  width: 40px;
  height: 30px;
  border: 1;
  border-radius: 0;
  background-color: colors.$background-white;
  box-shadow: 0px 2px 8px 0px colors.$black-shadow;

  text-transform: none;
}

.telemetryButton {
  min-width: 80px;
  width: 80px;
  height: 30px;
  border: 1;
  border-radius: 0;
  background-color: colors.$background-white;
  box-shadow: 0px 2px 8px 0px colors.$black-shadow;
}

.toggleGroup {
  background-color: colors.$white;
}
