@use "../../styles/colors";
@use "../../styles/typography";

.header {
  position: sticky;
  top: 0;

  display: flex;

  font-family: typography.$font-family-montserrat;
  font-size: rem-calc(12px);
  text-transform: uppercase;
  letter-spacing: rem-calc(1px);

  background-color: colors.$white;
  border-bottom: 1px solid colors.$light-gray;
  border-radius: 4px 4px 0 0;

  z-index: 1;

  div {
    padding: 4px 8px;
    border-right: 1px solid colors.$light-gray;

    &:last-child {
      border-right: 0;
    }
  }

  .col-ex {
    padding: 0;
    border-right: 0;
  }
}

.footer {
  bottom: 0;
  position: sticky;
  display: flex;
  background-color: colors.$lightest-gray;
  border-radius: 0 0 4px 4px;
  p {
    padding: 6px 8px;
  }
}

.stack {
  height: calc(100% - 30px);
  overflow: auto;
}

.console {
  height: 0;
  flex-grow: 1;
  width: 100%;

  background-color: colors.$white;

  border: 1px solid colors.$light-gray;
  border-radius: 4px;
}

.chip {
  font-family: typography.$font-family-monospace;
}

.ch {
  border-radius: 20px;
  padding: 4px 5px;
  flex: 0 0 25px;
  color: colors.$pixel-point-blue;
  cursor: pointer;
  .chevron {
    transition: transform 0.2s;
  }
  &:hover {
    background-color: colors.$pixel-point-blue-light-hover;
  }
}

.ch-open {
  flex: 0 0 20px;
  .chevron {
    transform: rotate(-90deg);
    transition: transform 0.2s;
  }
  .open & {
    .chevron {
      transform: rotate(0deg);
      transition: transform 0.2s;
    }
  }
}

.fields {
  display: grid;
  grid-template-rows: 0fr;
  transition: grid-template-rows 0.2s;

  .open & {
    grid-template-rows: 1fr;
    transition: grid-template-rows 0.2s;
  }

  & > div {
    overflow: hidden;
  }
}

.dt {
  flex: 0 0 190px;
}

.lg {
  flex: 1 auto;
  overflow-x: hidden;

  text-overflow: ellipsis;
  white-space: nowrap;
}

.key {
  width: 150px;
}

.value {
  width: auto;
}

.card {
  border: none;
  border-bottom: 1px solid colors.$light-gray;

  padding: 3px;
}

.chevron {
  width: 15px;
  margin: 2px 0 0 2px;

  cursor: pointer;
}

.summary {
  margin-left: 10px;
  white-space: nowrap;
  overflow: hidden;
}

.num {
  font-size: 0.7rem;
  color: colors.$middle-dark-gray;
  margin-left: 5px;
  display: inline-block;
  line-height: 28px;
  vertical-align: middle;
  width: 30px;
  text-align: center;
}

// menu support
.menu-open:not(.severity) {
  background-color: colors.$pixel-point-blue-medium-hover;
}
.menu-open.severity {
  opacity: 0.8;
}

// if the console is read-write, use cursor: pointer with hover behaviors
.read-write {
  // metric name for filter by metric name
  .metric-name:hover {
    background-color: colors.$light-gray;
    cursor: pointer;
  }

  // log severity for filter by severity
  .severity {
    cursor: pointer;
  }

  // field row for all field based processors
  [data-region="snapshot-field-row"]:hover {
    background-color: colors.$pixel-point-blue-light-hover;
    cursor: pointer;
  }
}
