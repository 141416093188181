@use "../../styles/colors";

.card {
  margin: auto;
  margin-bottom: 2rem;

  min-width: 800px;
  max-width: 1600px;
}

.padding {
  padding-left: 24px;
  padding-right: 24px;
}

.value {
  white-space: pre-wrap;
  max-width: 500px;
  margin-right: 6px;
}

.version {
  color: colors.$middle-gray;
}

.key {
  color: colors.$middle-dark-gray;
  min-width: 7rem;
}

.edit-box {
  max-width: 500px;

  div {
    padding: 0px;
  }

  textarea {
    line-height: 1.5;
  }
}

.edit-button {
  display: inline-block;
  position: relative;
  top: -1px;
}

.platform-icon {
  margin-right: 7px;
  position: relative;
  top: -1px;
}
