@use "../../styles/colors";

.stack {
  background-color: colors.$very-light-gray;
}

.card {
  max-width: 600px;
}

.actions {
  justify-content: end;
}
