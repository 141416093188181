@use "../../../../styles/colors";
@use "../../../../styles/typography";

.tableBox {
  border: 1px solid colors.$dark-gray;
  border-radius: 4px;

  background-color: colors.$white;
}

.table {
  background-color: colors.$white;
  border-collapse: collapse;
  border-radius: 4px;

  width: 100%;

  font-family: typography.$font-family-nunito-sans;
  font-size: 14px;
}

.th {
  & th {
    border-bottom: 1px solid colors.$dark-gray;

    padding: 8px;
  }
}

.tr {
  & td {
    padding: 4px 8px;
  }
}
