@use "../../styles/colors";

.paper {
  font-family: ui-monospace, SFMono-Regular, SF Mono, Consolas, Liberation Mono,
    Menlo, monospace;
  font-size: 12px;

  border: 1px solid colors.$middle-dark-gray !important;
  margin-top: 39px;
}

.block-header {
  border-radius: 4px;
  padding: 0 0.5rem;
  text-align: right;
  background-color: colors.$white;
}

.block-content {
  background-color: colors.$lightest-gray;
  border-top: 1px solid colors.$middle-dark-gray;
  padding: 0.5rem;
  border-radius: 0 0 4px 4px;
  white-space: pre-wrap;

  max-height: 200px;
  overflow: auto;
}
