@use "../../styles/colors";

.header {
    box-shadow: 0 1px 0 0 colors.$middle-light-gray;
    margin-bottom: 1px;

    height: 40px;    
    margin: 0.5rem;
}

.scroller {
    height: 40px;
}