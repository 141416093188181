@use "./colors";
@use "./typography";

html,
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  background-color: colors.$background-white;
}

a {
  color: colors.$semitone-blue;
}

* {
  box-sizing: border-box;
}

.content {
  padding: 2rem;
}

.react-flow__handle {
  visibility: hidden !important;
  width: 20px;
}

.react-flow__node {
  cursor: default;
}

.react-flow__pane {
  cursor: default;
}

.react-flow__edge {
  cursor: default;
}

.react-flow__controls {
  box-shadow: none;
  padding-right: 3px;
  margin-bottom: 13px;
}

.MuiPopper-root {
  z-index: 9999;
}
