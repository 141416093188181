.paper {
  width: 250px;
}

.chip {
  height: 22px;
  width: 22px;
}

.listItemChip {
  text-align: right;
}
