.root {
  min-width: 400px;
}

.icon {
  width: 50px;
  height: 50px;

  margin-right: 10px;

  background-repeat: no-repeat;
  background-position: center;
  background-size: 30px;
}

// This is used in the start adornment of the select when
// a platform is selected.
.value-icon {
  width: 30px;
  height: 30px;

  background-repeat: no-repeat;
  background-position: center;
  background-size: 20px;
}

.item {
  height: 48px;
}
