@use "../../styles/colors";

.overview-table-left {
  box-shadow: 0px 4px 8px 0px colors.$black-shadow;
  background-color: white;

  min-width: 400px;
  height: 100%;
}

// TODO: move to globals
.grid-background {
  background-image: linear-gradient(
      rgba(255, 255, 255, 0) 0%,
      rgba(250, 250, 250, 1) 100%
    ),
    radial-gradient(rgb(235, 235, 235) 2px, transparent 0);
  background-size: 20px 20px;
}
.overview-table-paper {
  height: 100%;
  padding: 20px;
  border-width: 0;
}

.grid-background {
  background-image: linear-gradient(
      rgba(255, 255, 255, 0) 0%,
      rgba(250, 250, 250, 1) 100%
    ),
    radial-gradient(rgb(235, 235, 235) 2px, transparent 0);
  background-size: 20px 20px;
}

.table-container {
  width: 100%;
  height: 50%;
}
