@use "../../styles/colors";

.listbox {
  font-family: monospace;
  padding: 0;
}

.option {
  font-size: 12px;
}

.paper {
  width: fit-content;
}

.input {
  border-color: colors.$middle-light-gray;
  display: flex;
  height: 36.5px;
}
