@use "../../styles/colors";

.label {
  font-size: 16px;
  color: colors.$pixel-point-blue;
  padding-left: unset;
  padding-right: unset;
}

.root {
  background-color: colors.$white;
  border: 1px solid colors.$pixel-point-blue;

  height: 26px;
  width: 26px;
}
