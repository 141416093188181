@use "../../styles/colors";

.copy {
  width: 26px;
}

.separator {
  display: none !important;
}

.chip {
  width: 100px;
  height: 24px;
}

.chip-label {
  font-size: 12px;
  font-weight: 600;
  color: colors.$white;
}

.outstanding {
  background-color: colors.$pixel-point-blue;
}

.expired {
  background-color: colors.$light-gray;
}

.redeemed {
  background-color: colors.$malachite;
}

.error {
  color: colors.$mui-error-dark;
}
