@use "../../styles/colors";

.dropdown-like-button {
  color: colors.$black;
  border-style: solid;
  padding: 7.5px 15px;
  border-color: colors.$middle-light-gray;
  display: flex;

  &.open {
    // Inset shadow for open state
    box-shadow: inset 0 0 0 1px colors.$pixel-point-blue;
    border-color: colors.$pixel-point-blue;
  }

  &:hover {
    background-color: colors.$white;
    border-color: colors.$dark-gray;
  }

  &.MuiButton-outlined:hover {
    border-color: colors.$middle-dark-gray;
  }
}
.arrow {
  fill: colors.$dark-gray;
  stroke: colors.$dark-gray;
}

.label {
  background: colors.$white;
  padding: 0px 5px;
  &.open {
    color: colors.$pixel-point-blue;
  }
}

.text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
