@use "../../styles/colors";

.grid {
  background-image: linear-gradient(
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 100%
    ),
    radial-gradient(rgb(235, 235, 235) 2px, transparent 0);

  background-size: 20px 20px;
}

.config-tab {
  padding: 0 1.5rem 1.5rem;
}

.box {
  box-shadow: 0 1px 0 0 colors.$middle-light-gray;
  height: 40px;
  padding-left: 16px;  
  margin: -1.5rem;
  margin-bottom: 1px;  
  border-top-left-radius: 3.5px;
  border-top-right-radius: 3.5px;
}

.scroller {
  height: 40px;
}

.apply-configuration-button {
  font-size: 1rem;
  top: -3px;
}