@use "../../../styles/colors";

.title-button-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.section-card {
  min-width: 800px;
  max-width: 1600px;

  margin: auto;
  margin-bottom: 2rem;

  border-color: colors.$middle-light-gray;
}
