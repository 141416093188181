@use "../../styles/typography";
@use "../../styles/colors";

.box {
  box-shadow: 0 1px 0 0 colors.$middle-light-gray;
  margin-bottom: 1px;

  height: 40px;
  padding-left: 32px;
}

.tab {
  font-family: typography.$font-family-montserrat;
  font-weight: 300;
  font-size: 0.75rem;
  color: colors.$black;

  margin-top: -4px;

  &:hover {
    font-weight: 400;
  }
}

.scroller {
  height: 40px;
}

.selected {
  color: colors.$black !important;
  font-weight: 600 !important;
}
