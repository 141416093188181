@use "../../styles/colors";

.menu {
  width: 300px;
}

.settings-icon {
  margin-right: 8px;
}

.info-stack {
  padding: 0 10px;
}

.settings-paper {
  overflow: visible;
  filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.32));
  margin-top: 10px;

  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: 14px;
    width: 16px;
    height: 16px;
    background-color: colors.$white;
    transform: translateY(-50%) rotate(45deg);
  }
}
