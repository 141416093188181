.item::before {
  content: none !important;
}

.dot {
  height: 4px;
  width: 4px;
  border-width: 4px !important;

  margin: 4px 0 !important;

  box-shadow: none;
}

.separator {
  margin-top: 10px;
}

.connector {
  margin-bottom: -10px;
}

.label {
  font-size: 20px;
  font-weight: 600;
}

.content {
  height: 150px;
}
