@use "../../styles/colors";

.stack {
  width: 100vw;
  height: 100vh;

  background: linear-gradient(
    180deg,
    colors.$gradient-gray 30.35%,
    colors.$white 80%
  );

  background-repeat: no-repeat;
  background-attachment: fixed;

  padding: 2rem;
}
