@use "../../../styles/colors";

.icon {
  background-repeat: no-repeat;
  background-position: center;
  background-size: 25px;

  width: 40px;
  height: 40px;
}

.cell {
  display: flex;
  align-items: center;
}

.link {
  // Unset all native button styles
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: none;

  color: colors.$semitone-blue;
  text-decoration: underline;
}
