@use "../../styles/colors";

.banner {
  min-width: 800px;
  max-width: 1600px;
  padding: 0.5rem;
  margin: auto;
  margin-bottom: 2rem;
  z-index: 1;
  position: relative;
}
