@use "../../styles/colors";

.form-container {
  border: 1px solid colors.$light-gray;
  border-radius: 4px;

  padding: 8px 16px 8px 16px;

  width: 550px;
  min-width: 550px;
  height: 0;
  flex-grow: 1;
}

.snapshot-container-left {
  min-width: 500px;
  flex-grow: 1;
  // Unclear why, but width needs specified for the flex-grow to behave
  width: 0;
}

.snapshot-container-right {
  flex-grow: 1;
  // Same as above
  width: 0;
}

.snapshot-menu ul {
  padding-top: 0;
  padding-bottom: 0;
}

.snapshot-menu li {
  padding-right: 30px;
}

.snapshot-menu li.snapshot-menu-heading {
  margin: auto 0;
  border-radius: 0;
  padding: 5px 10px;
  color: colors.$black;
  opacity: 1 !important;
  font-size: 0.8rem;
  background-color: colors.$light-gray;
}
