@use "../../styles/colors";

.card {
  width: 100px;
  height: 100px;

  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: colors.$lightest-gray;
    cursor: pointer;
  }
}
