@use "../../../styles/colors";

// TODO: This is identical to SourceTable/cells.module.scss. Refactor unless necessary

.icon {
  width: 40px;
  height: 40px;
  position: relative;
  left: 5px;
  top: 4px;
}

.cell {
  display: flex;
  align-items: center;
}

.link {
  // Unset all native button styles
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: none;

  color: colors.$semitone-blue;
  text-decoration: underline;
}
