@use "../../styles/colors";

.login-page {
  min-height: 100vh;

  background: linear-gradient(
    180deg,
    colors.$gradient-gray 30.35%,
    colors.$white 80%
  );

  background-repeat: no-repeat;
  background-attachment: fixed;

  padding: 2rem;
}

.card {
  padding: 1.5rem;
  width: 512px;
  z-index: 1;
}

.logo {
  margin-bottom: 2rem;
  z-index: 1;
}
