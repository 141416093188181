@use "../../styles/colors";

.ui-control-card {
  cursor: pointer;
  width: 150px;
  height: 120px;
  cursor: pointer;

  border-radius: 4px;

  background-color: colors.$pixel-point-blue;
  display: inline-flex;

  transition: 0.2s ease-in-out;
}

.ui-control-card:hover {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: colors.$pixel-point-blue-hover;
}

.icon {
  background-repeat: no-repeat;
  background-position: center;
  background-size: 55px; // size to scale icon
  width: 70px; //viewable area
  height: 70px;
}

.destination-icon {
  height: 55px;
  width: 55px;
}

.resource-card {
  width: 120px;
  height: 120px;
  cursor: pointer;

  border-radius: 8px;
  background: linear-gradient(
    315deg,
    colors.$white 21.02%,
    colors.$background-white 86.36%
  );

  border: 1px colors.$light-gray solid;

  box-shadow: 0px 2px 8px 0px colors.$black-shadow;

  transition: background-color 0.2s ease-in-out;
}

.resource-card:hover,
.processor-card:hover {
  background-color: colors.$light-gray;
  transition: background-color 0.2s ease-in-out;
  background: linear-gradient(
    315deg,
    colors.$light-gray 21.02%,
    colors.$light-gray 86.36%
  );
}

.resource-card-no-hover {
  width: 120px;
  height: 120px;

  border-radius: 8px;
  background: linear-gradient(
    315deg,
    colors.$white 21.02%,
    colors.$background-white 86.36%
  );

  border: 1px colors.$light-gray solid;

  box-shadow: 0px 2px 8px 0px colors.$black-shadow;
}

.action {
  height: 120px;
}

.title,
.description {
  font-weight: 600;
  white-space: nowrap;

  position: absolute;
  top: 150px;
  width: 200px;
  left: -40px;
  text-align: center;

  overflow: hidden;
  text-overflow: ellipsis;
}

.title {
  font-size: 16px;
  top: 120px;
}

.add-title {
  font-size: 16px;
}

.description {
  font-size: 12px;
  top: 150px;
}

.dummy-processor-card {
  height: 60px;
  width: 60px;
  cursor: default;

  border-radius: 8px;
  background: linear-gradient(
    315deg,
    colors.$white 21.02%,
    colors.$background-white 86.36%
  );

  border: 1px colors.$light-gray solid;
  box-shadow: 0px 2px 8px 0px colors.$black-shadow;

  transition: background-color 0.2s ease-in-out;

  padding: 13px;
}

.processor-card {
  @extend .dummy-processor-card;
  cursor: pointer;
}

.button {
  width: 150px;
  height: 120px;
  cursor: pointer;

  background-color: colors.$white;

  transition: background-color 0.2s ease-in-out;
}

.count-chip {
  position: absolute;
  top: -5px;
  right: -8px;
}

.overview-count-chip {
  position: absolute;
  top: -12px;
  left: -8px;
  background-color: colors.$white;
  border: 1px solid colors.$pixel-point-blue;

  height: 26px;
}

.overview-count-chip-label {
  font-size: 16px;
  color: colors.$pixel-point-blue;
}

.disabled {
  opacity: 0.4;
}

.paused {
  background-color: colors.$light-gray;
  opacity: 1;
}

.pause-icon {
  position: absolute;
  top: 10px;
  left: 10px;
}
